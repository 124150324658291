<template>
	<div>
		<base-table ref="usersTable" :headers="headers" :items="fetchUsers" table-top paginate>
			<template #action>
				<b-button variant="primary" class="cursor-pointer" @click="syncUsers">
					Sync data with MIS
				</b-button>
			</template>

			<template #cell(actions)="data">
				<div class="text-nowrap">
					<b-button
						v-b-tooltip.hover.top="'Permissons'"
						variant="outline-secondary"
						class="btn-icon cursor-pointer"
						size="sm"
						@click="openDetails(data)"
					>
						<feather-icon icon="Edit3Icon" size="16" />
					</b-button>
					<b-button
						v-if="!data.item.isFirstLogin"
						v-b-tooltip.hover.top="'Rest Password'"
						variant="outline-secondary"
						class="btn-icon cursor-pointer ml-50"
						size="sm"
						@click="resetPassword(data)"
					>
						<feather-icon icon="RotateCcwIcon" size="16" />
					</b-button>
				</div>
			</template>
			<template #cell(isFirstLogin)="{ value }">
				<feather-icon v-if="value" icon="XIcon" size="18" style="color: red" />
				<feather-icon v-else icon="CheckIcon" size="18" style="color: green" />
			</template>
		</base-table>

		<b-modal v-model="showDetails" ok-title="Save" button-size="sm" title="User details" @ok="savePermissions">
			<p class="font-weight-bold">Information</p>
			<div class="d-flex justify-content-between mb-2">
				<div class="">
					<h4 class="mb-1">{{ currentUser.name }}</h4>
					<p class="mb-0">
						<span class="border mr-75 p-25 rounded user-icon">
							<feather-icon icon="UserIcon" class="" size="14" />
						</span>
						<span class="font-weight-bold">{{ currentUser.typeName }}</span>
					</p>
				</div>
				<p class="align-self-end mb-0">
					<span class="border mr-75 p-25 rounded user-icon">
						<feather-icon icon="PhoneIcon" class="" size="14" />
					</span>
					<span class="font-weight-bold">{{ currentUser.phone }}</span>
				</p>
			</div>
			<hr />
			<p class="font-weight-bold">Permissions</p>
			<b-form-group v-for="group in permissionGroups" :key="group" :label="group" class="mb-2 perm-group">
				<b-form-checkbox-group
					v-model="currentUser.permissions"
					:options="allPermissions.get(group)"
					name="flavour-1"
					class="demo-inline-spacing"
				/>
			</b-form-group>
		</b-modal>
	</div>
</template>

<script>
import { BModal, BButton, BFormCheckboxGroup, BFormGroup, VBTooltip } from 'bootstrap-vue';
import startCase from 'lodash.startcase';
import store from '@/store';
import BaseTable from '@/components/BaseTable.vue';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import api from '@/utils/api';

export default {
	name: 'Users',

	components: { BaseTable, BModal, BButton, BFormCheckboxGroup, BFormGroup, FeatherIcon },

	directives: {
		'b-tooltip': VBTooltip,
	},

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'name', label: 'Name', sortable: true },
			{ key: 'typeName', label: 'Type', sortable: true },
			{ key: 'phone', label: 'Phone', sortable: true },
			{ key: 'isFirstLogin', label: 'Change password', sortable: true },
			{ key: 'actions', label: 'Actions' },
		],

		allPermissions: new Map(),

		showDetails: false,

		currentUser: {
			name: '',
			phone: '',
			type: '',
			permissions: [],
		},
	}),

	computed: {
		permissionGroups() {
			return Array.from(this.allPermissions.keys());
		},
	},

	created() {
		this.loadPermissions();
	},

	methods: {
		fetchUsers(ctx, searchQuery) {
			return store.dispatch('users/getUsers', { ...ctx, searchQuery });
		},

		async loadPermissions() {
			const data = await store.dispatch('users/getPermissions');

			const permissionGroups = new Map();
			data.forEach((perm) => {
				const group = perm.name.split('.')[0];

				const groupName = group === 'm&e' ? 'M&E' : startCase(group);
				const permName = startCase(perm.name.split('.')[1]);

				const oldGroup = permissionGroups.get(groupName) || [];
				permissionGroups.set(groupName, [
					...oldGroup,
					{
						text: permName,
						value: perm.id,
					},
				]);
			});

			this.allPermissions = permissionGroups;
		},

		async openDetails({ item }) {
			try {
				const permissions = await store.dispatch('users/getUserPermissions', item.id);
				this.currentUser = {
					...item,
					permissions: permissions.map((perm) => perm.id),
				};

				this.showDetails = true;
			} catch (error) {
				console.error(error);
			}
		},

		async savePermissions() {
			const data = {
				permissions: this.currentUser.permissions,
			};

			await store.dispatch('users/savePermissions', {
				data,
				userId: this.currentUser.id,
			});
		},

		async resetPassword({ item }) {
			await api.call({
				path: `users/${item.id}/reset-password`,
			});

			this.refresh();
		},

		async syncUsers() {
			await store.dispatch('users/syncUsers');
			this.refresh();
		},

		refresh() {
			this.$refs.usersTable.refresh();
		},
	},
};
</script>

<style lang="scss">
.user-icon {
	display: inline-block;
	line-height: 14px;
}

.demo-inline-spacing > * {
	margin-top: 0.7rem !important;
}

.perm-group {
	background-color: #f9f9f9;
	padding: 1rem;
	padding-top: 0;
	border: 1px solid #eee;
	border-radius: 4px;
}
</style>
